let styles = {
  html: {
    backgroundColor: '#E5E6F0',
    backgroundSize: "100% 100%",
    height: window.innerHeight,
    width: window.innerWidth,
    position: 'absolute',
    marginTop: -8,
    marginLeft: -8
  },
  video: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    minWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
  },
  paper: {
    padding: 30,
    marginTop: 10,
    marginLeft: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#E5E6F0',
    position: 'relative',
    borderRadius: 5
  },
  paper2: {
    padding: 20,
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: '#FFF',
    position: 'relative',
    border: '2px solid #19194b',
    borderRadius: 5,
    width: 900
  },
  avatar: {
    alignSelf: 'center',
    width: 152,
    height: 100,
    marginBottom: 10,
    marginTop: -10,
    marginRight: 30,
  },
  divForm: {
    alignSelf: 'center',
  },
  form: {
    width: '99%', // Fix IE 11 issue.
    paddingTop: 50,
    color: '#000',
  },
  input: {
    color: '#000',
    padding: 2,
  },
  submit: {
    marginTop: 10,
    marginBottom: 10,
    textTransform: 'none',
    backgroundColor: '#19194b',
  },
  link: {
    color: '#19194b',
  },

  directionRow : {
    display: 'flex',
    flexDirection: 'row',
  },

  directionColumn : {
    display: 'flex',
    flexDirection: 'column',
  },

  googleLogout: {
    width: 150,
    height: 96,
    margin: 20
  },

  button: {
    textTransform: 'none',
    marginBottom: 1,
  },

  button2: {
    textTransform: 'none',
    margin: 2,
    width: 200,
    height: 30,
    alignSelf: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  },

  edit_delete: {
    display: 'flex',
    flexDirection: 'row',
  },

  text: {
    color: '#323232',
    textAlign: 'center',
    padding: 5,
    marginTop: -5,
    marginBottom: 5,
    borderRadius: 5,
  },

  text2: {
    color: '#323232',
    textAlign: 'center',
    backgroundColor: '#32cd32',
    padding: 5,
    marginTop: -5,
    marginBottom: 5,
    borderRadius: 5,
    fontSize: 40,
  },
};

export default styles;