import * as React from 'react';
import api from '../../services/api';
import apiStringServer from '../../services/apiStringServer';

import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import {
  Collapse, Divider, ButtonGroup, Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions,
  TextField, FormControl, InputLabel, Select, Box, Card, CardContent, Grid, Avatar, Typography, Menu, Stepper, Step,
  StepLabel, StepContent, StepButton, Paper, Alert, IconButton
} from '@mui/material';

import { Add, Delete, Edit, Settings, FormatListNumbered, Description, AttachFile, Close, AddLink } from '@mui/icons-material';

import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from "recharts";

// web.cjs is required for IE11 support
import { useSpring, animated } from '@react-spring/web';

import logo from '../../assets/logo/logo_ksgc.png';

import styles from '../styles/styles';
//import citiesJSON from './utils/cities';

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const situations = [
  { id: 1, name: 'Ativo' },
  { id: 2, name: 'Suspenso para compra' },
];

export default function CustomizedTreeView({ props, profile }) {

  const statesArray = [
    { name: 'MG' },
    { name: 'AC' },
    { name: 'AL' },
    { name: 'AP' },
    { name: 'AM' },
    { name: 'BA' },
    { name: 'CE' },
    { name: 'DF' },
    { name: 'ES' },
    { name: 'GO' },
    { name: 'MA' },
    { name: 'MT' },
    { name: 'MS' },
    { name: 'PA' },
    { name: 'PB' },
    { name: 'PR' },
    { name: 'PE' },
    { name: 'PI' },
    { name: 'RJ' },
    { name: 'RN' },
    { name: 'RS' },
    { name: 'RO' },
    { name: 'RR' },
    { name: 'SC' },
    { name: 'SP' },
    { name: 'SE' },
    { name: 'TO' },
  ];

  let [stepsChart, setStepsChart] = React.useState([{ name: "Cadastro", value: 25, color: "#FF6B6B" },
    { name: "Anexo de nota fiscal", value: 0, color: "#4ECDC4" },
    { name: "Links dos processos", value: 0, color: "#45B7D1" },
    { name: "Homologação geral", value: 0, color: "#96CEB4" }]);

  const steps = [
    {
      label: 'Cadastro',
      description: `Este produto/serviço foi cadastrado no sistema.`,
    },
    {
      label: 'Anexo de Nota Fiscal',
      description: 'Anexar nota fiscal do produto/serviço.',
    },
    {
      label: 'Link do processo sobre o produto/serviço',
      description: 'Anexar link de processo ao produto/serviço.',
    },
    {
      label: 'Homologação Geral',
      description: 'Todas as etapas e requisitos concluidos.',
    },
  ];

  const [disabled, setDisabled] = React.useState(true);

  const [productStep, setProductStep] = React.useState('0');

  const [completed, setCompleted] = React.useState({});

  const [file, setFile] = React.useState(null);

  const [stateName, setStateName] = React.useState('Item');

  const [cidades, setCidades] = React.useState([]);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openProductDialog, setOpenProductDialog] = React.useState(false);

  const [openStepsDialog, setOpenStepsDialog] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const [name, setName] = React.useState('');
  const [type, setType] = React.useState('');
  const [parent_id, setParent_id] = React.useState('');
  const [parent_name, setParent_name] = React.useState('');
  const [tree, setTree] = React.useState([]);
  //const [treeJson, setTreeJson] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [secretaries, setSecretaries] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [contractTypes, setContractTypes] = React.useState([]);
  const [products, setProducts] = React.useState([]);

  const [photo_url, setPhoto_url] = React.useState(null);
  const [avatar, setAvatar] = React.useState(logo);

  let [msg, setMsg] = React.useState('');
  let [open, setOpen] = React.useState(false);
  let [openError, setOpenError] = React.useState(false);
  let [open2, setOpen2] = React.useState(false);
  let [openError2, setOpenError2] = React.useState(false);

  let [fileName, setFileName] = React.useState('');
  let [documents, setDocuments] = React.useState([]);
  let [documentId, setDocumentId] = React.useState('');
  let [documentName, setDocumentName] = React.useState('');

  let [link, setLink] = React.useState('');
  let [links, setLinks] = React.useState([]);
  let [linkId, setLinkId] = React.useState('');

  const [itemType, setItemType] = React.useState('select');
  const [situation, setSituation] = React.useState('select');
  const [code, setCode] = React.useState('');
  const [expenseNature, setExpenseNature] = React.useState('select');

  //material
  const [group, setGroup] = React.useState('');
  const [classification, setClassification] = React.useState('');
  const [pdm, setPdm] = React.useState('');
  const [material, setMaterial] = React.useState('');

  //serviço
  const [session, setSession] = React.useState('');
  const [division, setDivision] = React.useState('');
  const [serviceGroup, setServiceGroup] = React.useState('');
  const [serviceClass, setServiceClass] = React.useState('');
  const [serviceSubclass, setServiceSubclass] = React.useState('');
  const [service, setService] = React.useState('');

  const [supplyLine, setSupplyLine] = React.useState('');
  const [expenseProduct, setExpenseProduct] = React.useState('');
  const [productValue, setProductValue] = React.useState('');
  const [productDescription, setProductDescription] = React.useState('');

  const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false);
  const [productArray, setProductArray] = React.useState([]);

  //material
  const [groupList, setGroupList] = React.useState([]);
  const [classList, setClassList] = React.useState([]);
  const [pdmList, setPdmList] = React.useState([]);
  const [materialList, setMaterialList] = React.useState([]);

  //serviço
  const [sessionList, setSessionList] = React.useState([]);
  const [divisionList, setDivisionList] = React.useState([]);
  const [serviceGroupList, setServiceGroupList] = React.useState([]);
  const [serviceClassList, setServiceClassList] = React.useState([]);
  const [serviceSubclassList, setServiceSubclassList] = React.useState([]);
  const [serviceList, setServiceList] = React.useState([]);

  const [groupById, setGroupById] = React.useState('');
  const [classById, setClassById] = React.useState('');
  const [pdmById, setPdmById] = React.useState('');
  const [materialById, setMaterialById] = React.useState('');

  const [sessionById, setSessionById] = React.useState('');
  const [divisionById, setDivisionById] = React.useState('');
  const [serviceGroupById, setServiceGroupById] = React.useState('');
  const [serviceClassById, setServiceClassById] = React.useState('');
  const [serviceSubclassById, setServiceSubclassById] = React.useState('');
  const [serviceById, setServiceById] = React.useState('');

  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [product_id, setProduct_id] = React.useState('');
  const [avatarEdit, setAvatarEdit] = React.useState('');

  React.useEffect(() => {

    getAllTree();
    getGroupList();
    getSessionList();

  }, []);

  async function getAllTree() {

    while (states.length) {
      states.pop();
    }
    while (cities.length) {
      cities.pop();
    }
    while (secretaries.length) {
      secretaries.pop();
    }
    while (types.length) {
      types.pop();
    }
    while (categories.length) {
      categories.pop();
    }
    while (contractTypes.length) {
      contractTypes.pop();
    }
    while (products.length) {
      products.pop();
    }

    try {
      let resp = await api.get('/alltree', {
        params: {
          //user_id: profile.id,
          place: profile.place,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {

            //await setTreeJson(resp.data);
            for (let i = 0; i < resp.data.length; i++) {
              if (resp.data[i].type === 'Estado') {
                await setStates([]);
                states.push(resp.data[i]);
                setStates(states);
              }
            }
            for (let i = 0; i < resp.data.length; i++) {
              if (resp.data[i].type === 'Município') {
                await setCities([]);
                cities.push(resp.data[i]);
                setCities(cities);
              }
            }
            for (let i = 0; i < resp.data.length; i++) {
              if (profile.secretary_id) {
                if (resp.data[i].type === 'Secretaria' /*&& resp.data[i].id === profile.secretary_id*/) {
                  await setSecretaries([]);
                  secretaries.push(resp.data[i]);
                  setSecretaries(secretaries);
                }
              } else {
                if (resp.data[i].type === 'Secretaria') {
                  await setSecretaries([]);
                  secretaries.push(resp.data[i]);
                  setSecretaries(secretaries);
                }
              }
            }
            for (let i = 0; i < resp.data.length; i++) {
              if (resp.data[i].type === 'Tipo') {
                await setTypes([]);
                types.push(resp.data[i]);
                setTypes(types);
              }
            }
            for (let i = 0; i < resp.data.length; i++) {
              if (resp.data[i].type === 'Categoria/Agrupamento') {
                await setCategories([]);
                categories.push(resp.data[i]);
                setCategories(categories);
              }
            }
            for (let i = 0; i < resp.data.length; i++) {
              if (resp.data[i].type === 'Tipo de Contratação') {
                await setContractTypes([]);
                contractTypes.push(resp.data[i]);
                setContractTypes(contractTypes);
              }
            }


            try {
              let resp = await api.get('/products', {
                params: {
                  place: profile.place,
                }
              });
              if (resp != null) {
                if (resp.data) {
                  if (JSON.stringify(resp.data).includes('name')) {
                    await setProducts([]);
                    for (let i = 0; i < resp.data.length; i++) {
                      products.push({
                        id: resp.data[i].id,
                        code: resp.data[i].code,
                        name: resp.data[i].name,
                        type: resp.data[i].product_group,
                        parent_id: resp.data[i].parent_id,
                        created_at: resp.data[i].created_at,
                        updated_at: resp.data[i].updated_at,
                        item_type: resp.data[i].item_type,
                        expense_nature: resp.data[i].expense_nature,
                        product_group: resp.data[i].product_group,
                        class: resp.data[i].class,
                        pdm: resp.data[i].pdm,
                        material: resp.data[i].material,
                        session: resp.data[i].session,
                        division: resp.data[i].division,
                        service_group: resp.data[i].service_group,
                        service_class: resp.data[i].service_class,
                        service_subclass: resp.data[i].service_subclass,
                        service: resp.data[i].service,
                        supply_line: resp.data[i].supply_line,
                        expense_product: resp.data[i].expense_product,
                        product_value: resp.data[i].product_value,
                        description: resp.data[i].description,
                        photo_url: resp.data[i].photo_url,
                        product_step: resp.data[i].product_step,
                      });
                    }
                    await setProducts(products);
                    //console.log('Produtos: ' + JSON.stringify(products) + '\nCategorias: ' + JSON.stringify(categories));
                  }
                }
              }
            } catch (error) {
              console.log(error);
            }

          } else {
            console.log(resp.data);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

  }

  async function getTree(type) {

    try {

      try {
        let resp = await api.get('/tree', {
          params: {
            type: type,
          }
        });
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('name')) {

              setTree(resp.data);

            } else {
              //console.log(resp.data);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }

    } catch (error) {
      console.log(error);
    }

  }

  async function getGroupById(groupValue) {
    await setGroupById('');

    try {
      const response = await fetch(`https://compras.dados.gov.br/materiais/doc/grupo/${groupValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setGroupById(JSON.stringify(data.descricao).replace(/"/g, ''));
        }
      }
    } catch (error) {
      //console.log(error);
    }

  }

  async function getClassById(classValue) {
    await setClassById('');

    try {
      const response = await fetch(`https://compras.dados.gov.br/materiais/id/classe/${classValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setClassById(JSON.stringify(data.descricao).replace(/"/g, ''));
        }
      }
    } catch (error) {
      //console.log(error);
    }

  }

  async function getPdmById(pdmValue) {
    await setPdmById('');

    try {
      const response = await fetch(`https://compras.dados.gov.br/materiais/id/pdm/${pdmValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setPdmById(JSON.stringify(data.descricao).replace(/"/g, ''));
        }
      }
    } catch (error) {
      //console.log(error);
    }

  }

  async function getMaterialById(materialValue) {
    await setMaterialById('');

    try {
      const response = await fetch(`https://compras.dados.gov.br/materiais/id/material/${materialValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setMaterialById(JSON.stringify(data.descricao).replace(/"/g, ''));
        }
      }
    } catch (error) {
      //console.log(error);
    }

  }

  async function getSessionById(sessionValue) {
    await setSessionById('');

    try {
      const response = await fetch(`https://compras.dados.gov.br/servicos/id/secao/${sessionValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setSessionById(JSON.stringify(data.descricao).replace(/"/g, ''));
        }
      }
    } catch (error) {
      //console.log(error);
    }

  }

  async function getDivisionById(divisionValue) {
    await setDivisionById('');

    try {
      const response = await fetch(`https://compras.dados.gov.br/servicos/id/divisao/${divisionValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setDivisionById(JSON.stringify(data.descricao).replace(/"/g, ''));
        }
      }
    } catch (error) {
      //console.log(error);
    }

  }

  async function getServiceGroupById(serviceGroupValue) {
    await setServiceGroupById('');

    try {
      const response = await fetch(`https://compras.dados.gov.br/servicos/id/grupo/${serviceGroupValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setServiceGroupById(JSON.stringify(data.descricao).replace(/"/g, ''));
        }
      }
    } catch (error) {
      //console.log(error);
    }

  }

  async function getServiceClassById(serviceClassValue) {
    await setServiceClassById('');

    try {
      const response = await fetch(`https://compras.dados.gov.br/servicos/id/classe/${serviceClassValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setServiceClassById(JSON.stringify(data.descricao).replace(/"/g, ''));
        }
      }
    } catch (error) {
      //console.log(error);
    }

  }

  async function getServiceSubclassById(serviceSubclassValue) {
    await setServiceSubclassById('');

    try {
      const response = await fetch(`https://compras.dados.gov.br/servicos/id/subclasse/${serviceSubclassValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setServiceSubclassById(JSON.stringify(data.descricao).replace(/"/g, ''));
        }
      }
    } catch (error) {
      //console.log(error);
    }

  }

  async function getServiceById(serviceValue) {
    await setServiceById('');

    try {
      const response = await fetch(`https://compras.dados.gov.br/servicos/id/servico/${serviceValue}.json`);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setServiceById(JSON.stringify(data.descricao).replace(/"/g, ''));
        }
      }
    } catch (error) {
      //console.log(error);
    }

  }

  async function getGroupList() {
    await setGroupList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/materiais/v1/grupos.json');

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setGroupList(data._embedded.grupos);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getClassList(group) {
    await setClassList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/materiais/v1/classes.json?codigo_grupo=' + group);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setClassList(data._embedded.classes);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getPdmList(classification) {
    await setPdmList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/materiais/v1/pdms.json?codigo_classe=' + classification);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setPdmList(data._embedded.pdms);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getMaterialList(pdm) {
    await setMaterialList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/materiais/v1/materiais.json?pdm=' + pdm); // + '&classe=' + classification + '&grupo=' + group

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setMaterialList(data._embedded.materiais);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getSessionList() {
    await setSessionList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/servicos/v1/secoes.json');

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setSessionList(data._embedded.secoes);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getDivisionList(session) {
    await setDivisionList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/servicos/v1/divisoes.json?codigo_secao=' + session);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setDivisionList(data._embedded.divisoes);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getServiceGroupList(division) {
    await setServiceGroupList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/servicos/v1/grupos.json?codigo_divisao=' + division);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setServiceGroupList(data._embedded.grupos);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getServiceClassList(group) {
    await setServiceClassList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/servicos/v1/classes.json?codigo_grupo=' + group);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setServiceClassList(data._embedded.classes);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getServiceSubclassList(classification) {
    await setServiceSubclassList([]);

    try {
      const response = await fetch('https://compras.dados.gov.br/servicos/v1/subclasses.json?codigo_classe=' + classification);

      const data = await response.json();

      if (data) {
        if (JSON.stringify(data).includes('descricao')) {
          setServiceSubclassList(data._embedded.subclasses);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function getServiceList(subclass) {
    await setServiceList([]);

    if (subclass !== null) {
      try {
        const response = await fetch('https://compras.dados.gov.br/servicos/v1/servicos.json?subclasse=' + subclass + '&classe=' +
          serviceClass + '&grupo=' + serviceGroup + '&divisao=' + division + '&secao=' + session);

        const data = await response.json();

        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setServiceList(data._embedded.servicos);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await fetch('https://compras.dados.gov.br/servicos/v1/servicos.json?classe=' +
          serviceClass + '&grupo=' + serviceGroup + '&divisao=' + division + '&secao=' + session);

        const data = await response.json();

        if (data) {
          if (JSON.stringify(data).includes('descricao')) {
            setServiceList(data._embedded.servicos);
          }
        }
      } catch (error) {
        try {
          const response = await fetch('https://compras.dados.gov.br/servicos/v1/servicos.json?grupo=' + serviceGroup + '&divisao=' +
            division + '&secao=' + session);

          const data = await response.json();

          if (data) {
            if (JSON.stringify(data).includes('descricao')) {
              setServiceList(data._embedded.servicos);
            }
          }
        } catch (error) {
          console.log('Erro ao procurar serviços: ' + error);
        }
      }
    }

  }

  async function handleSubmitTreeItem(name, type, parent_id) {

    if (parent_id === 'select') {
      console.log('Selecione um item pai');
    }

    const data = new FormData();

    data.append('name', name);
    data.append('type', type);
    data.append('parent_id', parent_id);
    data.append('user_id', profile.id);
    data.append('place', profile.place);

    try {
      let resp = await api.post('/tree', data);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('registred')) {

            getAllTree();

          } else {
            alert(resp.data);
          }
        }
      }
    } catch (err) {
      alert(err);
    }

  }

  async function handleNewImage(e) {

    //e.preventDefault();

    const photo = e.target.files[0];

    await setPhoto_url(photo);
    const fileSize = e.target.files[0].size;

    if (fileSize > 3000000) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setMsg('Tamanho máximo permitido: 3 MB');
      setOpenError(true);
      setOpen(false);
      setPhoto_url(null);
    } else {
      const objectUrl = URL.createObjectURL(photo_url);
      setAvatar(objectUrl);
    }

  }

  async function handleSee(product_id) {

    for (let i = 0; i < productArray.length; i++) {
      productArray.pop();
    }

    await setProduct_id(product_id);

    handleDocuments(product_id);
    handleLinks(product_id);

    try {
      let resp = await api.get('/product', {
        params: {
          id: product_id,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            console.log(JSON.stringify(resp.data));

            // await setProductArray([]);
            // await productArray.push(resp.data);
            // await setProductArray(productArray);
            // await setProductStep(resp.data.product_step);
            setProductArray([resp.data]);
            await setProductStep(resp.data.product_step);

            const completed = {};
            for (let i = 0; i < resp.data.product_step.length; i++) {
              completed[resp.data.product_step.charAt(i)] = true;
            }
            console.log(JSON.stringify(completed));
            //setCompleted({});
            //await setCompleted(completed);
            await setCompleted(completed);

            if (resp.data.product_step.length === 4) {
              await setDisabled(false);
            }

            let stepsChart = [{ name: "Cadastro", value: 25, color: "#FF6B6B" },
            { name: "Anexo de nota fiscal", value: 0, color: "#4ECDC4" },
            { name: "Links dos processos", value: 0, color: "#45B7D1" },
            { name: "Homologação geral", value: 0, color: "#96CEB4" }];

            if (resp.data.product_step.includes('1')) {
              stepsChart[1].value = 25;
            }
            if (resp.data.product_step.includes('2')) {
              stepsChart[2].value = 25;
            }
            if (resp.data.product_step.includes('3')) {
              stepsChart[3].value = 25;
            }

            await setStepsChart(stepsChart);

            setOpenDetailsDialog(true);
            await getGroupById(resp.data.product_group);
            await getClassById(resp.data.class);
            await getPdmById(resp.data.pdm);
            await getMaterialById(resp.data.material);
            await getSessionById(resp.data.session);
            await getDivisionById(resp.data.division);
            await getServiceGroupById(resp.data.service_group);
            await getServiceClassById(resp.data.service_class);
            await getServiceSubclassById(resp.data.service_subclass);
            await getServiceById(resp.data.service);
            //   console.log('etapa: ' + JSON.stringify(resp.data.product_step))
            //   console.log('depois: ' + JSON.stringify(productStep))
            //   console.log('completed: ' + JSON.stringify(completed));

            //alert('completed: ' + JSON.stringify(completed)); // 0: true  deveria vir 0: true, 1: true
            //alert('Etapa: ' + JSON.stringify(resp.data.product_step)); // 01 está certo
            //alert('Com dados errados pois ainda não atualizou o estado: ' + JSON.stringify(productStep)); // 0 deveria vir 01

          }
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  async function handleDocuments(product_id) {

    try {
      let resp = await api.get('/documents', {
        params: {
          product_id: product_id,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('document')) {

            setDocuments(resp.data);

            //alert('Documentos: ' + JSON.stringify(resp.data));

          } else {
            setDocuments([]);
            setMsg(resp.data);
            const finalmsg = msg.toString().split('"').join('');
            setMsg(finalmsg);
            setOpen(false);
            if (msg === '[]') {
              setOpenError(false);
            } else {
              setOpenError(true);
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
      setMsg('Usuário incorreto. ' + err);
      setOpen(false);
      setOpenError(true);
    }

  }

  async function handleLinks(product_id) {

    try {
      let resp = await api.get('/links', {
        params: {
          product_id: product_id,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('process')) {

            setLinks(resp.data);

            //alert('Documentos: ' + JSON.stringify(resp.data));

          } else {
            setLinks([]);
            setMsg(resp.data);
            const finalmsg = msg.toString().split('"').join('');
            setMsg(finalmsg);
            setOpen2(false);
            if (msg === '[]' || msg === '') {
              setOpenError2(false);
            } else {
              setOpenError2(true);
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
      setMsg('Erro ao tentar buscar os links de processos dos produtos/serviços. ' + err);
      setOpen2(false);
      setOpenError2(true);
    }

  }

  async function handleNewFile(e) {
    //await e.preventDefault();

    //alert(e.target.files[0]);

    setFile(e.target.files[0]);
    const fileSize = e.target.files[0].size;

    if (fileSize > 5000000) {
      //alert('Tamanho máximo permitido: 5 MB');
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setMsg('Tamanho máximo permitido: 5 MB');
      setOpen(false);
      setOpenError(true);
    } else {
      setFileName(e.target.files[0].name);

      const data = new FormData(); //criar rota de inserir documento
      data.append('product_id', product_id);
      data.append('document', e.target.files[0]);

      try {
        let resp = await api.post('/storedocument', data);
        if (resp != null) {
          if (resp.data) {
            if (JSON.stringify(resp.data).includes('registred')) {
              //documento salvo
              handleDocuments(product_id);
              setMsg('Documento anexado com sucesso.');
              setOpen(true);
              setOpenError(false);
            } else {
              setMsg(resp.data);
              setOpen(false);
              setOpenError(true);
            }
          }
        }
      } catch (err) {
        setMsg('Não foi possível anexar o documento. ' + err);
        setOpen(false);
        setOpenError(true);
      }
    }
  }

  async function deleteDocument(document_id, document_name) {

    try {
      let resp = await api.get('/deletedocument', {
        params: {
          document_id: document_id,
          document_name: document_name,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('deleted')) {
            //documento deletado
            handleDocuments(product_id);
            setMsg('Documento deletado com sucesso.');
            setOpen(true);
            setOpenError(false);
          } else {
            setMsg(resp.data);
            setOpen(false);
            setOpenError(true);
          }
        }
      }
    } catch (err) {
      setMsg('Não foi possível deletar o documento. ' + err);
      setOpen(false);
      setOpenError(true);
    }

  }

  async function deleteLink(link_id) {

    try {
      let resp = await api.get('/deletelink', {
        params: {
          link_id: link_id,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('deleted')) {
            //link deletado
            handleLinks(product_id);
            setMsg('Link deletado com sucesso.');
            setOpen2(true);
            setOpenError2(false);
          } else {
            setMsg(resp.data);
            setOpen2(false);
            setOpenError2(true);
          }
        }
      }
    } catch (err) {
      setMsg('Não foi possível deletar o link. ' + err);
      setOpen2(false);
      setOpenError2(true);
    }

  }

  async function handleLink() {
    //e.preventDefault();

    try {
      let resp = await api.get('/storelink', {
        params: {
          product_id: product_id,
          link: link,
        }
      });
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('registred')) {
            //link salvo
            handleLinks(product_id);
            setMsg('Link anexado com sucesso.');
            setOpen2(true);
            setOpenError2(false);
          } else {
            setMsg(resp.data);
            setOpen2(false);
            setOpenError2(true);
          }
        }
      }
    } catch (err) {
      setMsg('Não foi possível anexar o link. ' + err);
      setOpen2(false);
      setOpenError2(true);
    }
  }

  async function handleSubmitProduct(photo_url, parent_id, itemType, situation, code, name, expenseNature, group, classification, pdm,
    material, session, division, serviceGroup, serviceClass, serviceSubclass, service, supplyLine, expenseProduct, productValue, productDescription) {

    if (parent_id === 'select') {
      console.log('Selecione um item pai');
    }
    if (situation === 'select') {
      console.log('Selecione uma situação');
    }
    if (code === '') {
      console.log('Informe o código');
    }
    if (expenseNature === 'select') {
      console.log('Selecione uma natureza de despesa');
    }
    if (productValue === '') {
      console.log('Informe o valor');
    }
    if (itemType === 'select') {
      console.log('Selecione um tipo de item');
    }

    const data = new FormData();

    data.append('photo_url', photo_url);
    data.append('parent_id', parent_id);
    data.append('secretary_id', secretaries[0].id);
    data.append('item_type', itemType);
    data.append('situation', situation);
    data.append('code', code);
    data.append('name', name);
    data.append('expenseNature', expenseNature);
    data.append('group', group);
    data.append('classification', classification);
    data.append('pdm', pdm);
    data.append('material', material);
    data.append('session', session);
    data.append('division', division);
    data.append('serviceGroup', serviceGroup);
    data.append('serviceClass', serviceClass);
    data.append('serviceSubclass', serviceSubclass);
    data.append('service', service);
    data.append('supplyLine', supplyLine);
    data.append('expenseProduct', expenseProduct);
    data.append('productValue', productValue);
    data.append('productDescription', productDescription);

    data.append('user_id', profile.id);

    try {
      let resp = await api.post('/product', data);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('registred')) {

            getAllTree();

          } else {
            alert(resp.data);
          }
        }
      }
    } catch (err) {
      alert(err);
    }

  }

  async function handleChangeParent(e) {
    setParent_id(e.target.value);

    /*for (let i = 0; i < cidades.length; i++) {
      cidades.pop();
    }
    await setCidades(cidades);

    await setStateName(document.getElementById(e.target.value).label);

    for (let i = 0; i < citiesJSON.length; i++) {
      if (citiesJSON[i].sigla.includes(stateName)) {
        for (let x = 0; x < citiesJSON[i].cidades.length; x++) {
          cidades.push(citiesJSON[i].cidades[x]);
        }
      }
    }
    await setCidades(cidades);*/

    try {
      let resp = await api.get('/places');
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('name')) {
            await setCidades(resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }

  }

  async function handleEdit(id, item_type, name, code, expense_nature, product_group, classification, pdm, material,
    session, division, service_group, service_class, service_subclass, service, supply_line, expense_product, product_value,
    description, photo_url) {
    await setOpenEditDialog(true);
    setProduct_id(id);
    setItemType(item_type);
    setName(name);
    setCode(code);
    setExpenseNature(expense_nature);
    setGroup(product_group);
    getClassList(product_group);
    setClassification(classification);
    getPdmList(classification);
    setPdm('0' + pdm);
    getMaterialList('0' + pdm);
    setMaterial(material);
    setSession(session);
    getDivisionList(session);
    setDivision(division);
    getServiceGroupList(division);
    setServiceGroup(service_group);
    getServiceClassList(service_group);
    setServiceClass(service_class);
    getServiceSubclassList(service_class);
    setServiceSubclass(service_subclass);
    getServiceList(service_subclass);
    setService(service);
    setSupplyLine(supply_line);
    setExpenseProduct(expense_product);
    setProductValue(product_value);
    setProductDescription(description);
    setAvatarEdit(photo_url);
  }

  async function handleEditProduct() {
    const data = new FormData();

    data.append('product_id', product_id);
    data.append('user_id', profile.id);
    data.append('item_type', itemType);
    data.append('name', name);
    data.append('code', code);
    data.append('expense_nature', expenseNature);
    data.append('product_group', group);
    data.append('classification', classification);
    data.append('pdm', pdm);
    data.append('material', material);
    data.append('session', session);
    data.append('division', division);
    data.append('service_group', serviceGroup);
    data.append('service_class', serviceClass);
    data.append('service_subclass', serviceSubclass);
    data.append('service', service);
    data.append('supply_line', supplyLine);
    data.append('expense_product', expenseProduct);
    data.append('product_value', productValue);
    data.append('description', productDescription);
    data.append('photo_url', avatar);
    data.append('current_image', avatarEdit);

    try {
      let resp = await api.post('updateproduct', data);
      if (resp != null) {
        if (resp.data) {
          if (JSON.stringify(resp.data).includes('updated')) {
            getAllTree();
            getGroupList();
            getSessionList();
          } else {
            alert(resp.data);
          }
        }
      }
    } catch (error) {
      alert(error);
    }
  }

  function handleStep(step) {

    setActiveStep(step);

  }

  return (
    <Card {...props} sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>

      <CardContent>
        <ButtonGroup
          orientation="vertical"
          aria-label="vertical outlined button group"
        >
          {profile.type === 1 ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Button
                key="state"
                sx={styles.button}
                onClick={() => { setOpenDialog(true); setName(''); setType('Estado'); setParent_id(''); while (tree.length) { tree.pop(); } }}
              >
                <Add />Cadastrar Estado
              </Button>
              <Button
                key="city"
                sx={styles.button}
                onClick={() => { setOpenDialog(true); setName(''); setType('Município'); getTree('Estado'); while (tree.length) { tree.pop(); } }}
              >
                <Add />Cadastrar Município
              </Button>
              <Button
                key="secretary"
                sx={styles.button}
                onClick={() => { setOpenDialog(true); setName(''); setType('Secretaria'); getTree('Município'); while (tree.length) { tree.pop(); } }}
              >
                <Add />Cadastrar Secretaria
              </Button>
              <Button
                key="type"
                sx={styles.button}
                onClick={() => { setOpenDialog(true); setName(''); setType('Tipo'); getTree('Secretaria'); while (tree.length) { tree.pop(); } }}
              >
                <Add />Cadastrar Tipo
              </Button>
              <Button
                key="category"
                sx={styles.button}
                onClick={() => { setOpenDialog(true); setName(''); setType('Categoria/Agrupamento'); getTree('Tipo'); while (tree.length) { tree.pop(); } }}
              >
                <Add />Cadastrar Categoria/Agrupamento
              </Button>
              <Button
                key="contract_type"
                sx={styles.button}
                onClick={() => { setOpenDialog(true); setName(''); setType('Tipo de Contratação'); getTree('Categoria/Agrupamento'); while (tree.length) { tree.pop(); } }}
              >
                <Add />Cadastrar Tipo de Contratação
              </Button>
            </Box>
          ) : null}
          {profile.type === 5 ? (
            <Button
              key="products"
              sx={styles.button}
              onClick={() => { setOpenProductDialog(true); setName(''); setType('Produto'); getTree('Tipo de Contratação'); while (tree.length) { tree.pop(); } }}
            >
              <Add />Cadastrar Produto/Serviço
            </Button>
          ) : null}
        </ButtonGroup>
      </CardContent>

      <Divider orientation="vertical" flexItem />

      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <TreeView
            aria-label="customized"
            defaultExpanded={['1']}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<CloseSquare />}
            sx={{ height: 764, flexGrow: 1, maxWidth: 800, overflowY: 'auto', overflowX: 'auto' }}
          >
            {states.map((item) =>
              <Box sx={styles.directionRow}>
                <StyledTreeItem nodeId={item.id} label={item.name}>
                  {cities.map((item2) =>
                    item2.parent_id === item.id ? (
                      <Box sx={styles.directionRow}>
                        <StyledTreeItem nodeId={item2.id} label={item2.name}>
                          {secretaries.map((item3) =>
                            item3.parent_id === item2.id ? (
                              <Box sx={styles.directionRow}>
                                <StyledTreeItem nodeId={item3.id} label={item3.name}>
                                  {types.map((item4) =>
                                    item4.parent_id === item3.id ? (
                                      <Box sx={styles.directionRow}>
                                        <StyledTreeItem nodeId={item4.id} label={item4.name}>
                                          {categories.map((item5) =>
                                            item5.parent_id === item4.id ? (
                                              <Box sx={styles.directionRow}>
                                                <StyledTreeItem nodeId={item5.id} label={item5.name}>
                                                  {contractTypes.map((item6) =>
                                                    item6.parent_id === item5.id ? (
                                                      <Box sx={styles.directionRow}>
                                                        <StyledTreeItem nodeId={item6.id} label={item6.name}>
                                                          <TreeView
                                                            aria-label="customized"
                                                            defaultExpanded={['1']}
                                                            defaultCollapseIcon={<MinusSquare />}
                                                            defaultExpandIcon={<PlusSquare />}
                                                            defaultEndIcon={<CloseSquare />}
                                                            sx={{ flexGrow: 1, maxWidth: 800, overflowY: 'auto', overflowX: 'auto' }}
                                                          >
                                                            {products.map((item7) =>
                                                              item7.parent_id === item6.id ? (
                                                                <Box sx={styles.directionRow}>
                                                                  <StyledTreeItem
                                                                    nodeId={item7.id}
                                                                    label={`${item7.code} - ${item7.name}`}
                                                                    onClick={() => { handleSee(item7.id) }}
                                                                  >
                                                                  </StyledTreeItem>
                                                                  {profile.type === 5 ? (
                                                                    <Box sx={styles.edit_delete}>
                                                                      <Edit
                                                                        color="primary"
                                                                        onClick={() => handleEdit(item7.id, item7.item_type, item7.name,
                                                                          item7.code, item7.expense_nature, item7.product_group, item7.class,
                                                                          item7.pdm, item7.material, item7.session, item7.division,
                                                                          item7.service_group, item7.service_class, item7.service_subclass,
                                                                          item7.service, item7.supply_line, item7.expense_product,
                                                                          item7.product_value, item7.description, item7.photo_url)}
                                                                      />
                                                                      <Delete />
                                                                    </Box>
                                                                  ) : profile.type === 4 ? (
                                                                    <Box sx={styles.edit_delete}>
                                                                      <Settings color="primary" />
                                                                    </Box>
                                                                  ) : null}
                                                                </Box>
                                                              ) : null
                                                            )}
                                                          </TreeView>
                                                        </StyledTreeItem>
                                                        {profile.type === 1 ? (
                                                          <Box sx={styles.edit_delete}>
                                                            <Edit color="primary" /> <Delete />
                                                          </Box>
                                                        ) : null}
                                                      </Box>
                                                    ) : null
                                                  )}
                                                </StyledTreeItem>
                                                {profile.type === 1 ? (
                                                  <Box sx={styles.edit_delete}>
                                                    <Edit color="primary" /> <Delete />
                                                  </Box>
                                                ) : null}
                                              </Box>
                                            ) : null
                                          )}
                                        </StyledTreeItem>
                                        {profile.type === 1 ? (
                                          <Box sx={styles.edit_delete}>
                                            <Edit color="primary" /> <Delete />
                                          </Box>
                                        ) : null}
                                      </Box>
                                    ) : null
                                  )}
                                </StyledTreeItem>
                                {profile.type === 1 ? (
                                  <Box sx={styles.edit_delete}>
                                    <Edit color="primary" /> <Delete />
                                  </Box>
                                ) : null}
                              </Box>
                            ) : null
                          )}
                        </StyledTreeItem>
                        {profile.type === 1 ? (
                          <Box sx={styles.edit_delete}>
                            <Edit color="primary" /> <Delete />
                          </Box>
                        ) : null}
                      </Box>
                    ) : null
                  )}
                </StyledTreeItem>
                {profile.type === 1 ? (
                  <Box sx={styles.edit_delete}>
                    <Edit color="primary" /> <Delete />
                  </Box>
                ) : null}
              </Box>
            )}
          </TreeView>
        </Box>
      </CardContent>

      <Dialog open={openDetailsDialog} onClose={() => setOpenDetailsDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Produto</DialogTitle>
        <DialogContent>
          <DialogContentText color="#000">
            Detalhes do produto/serviço.
          </DialogContentText>

          {productArray.map((product) =>
            <Box>
              <Avatar
                src={`${apiStringServer}/products_img/${product.photo_url}`}
                sx={{ width: 200, height: 200, borderRadius: 0, borderWidth: 1, borderColor: '#000' }}
              />
              <Typography color="textSecondary" variant="body2">
                Código: {product.code}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Nome: {product.name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Descrição: {product.description}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Valor: R$ {product.product_value}
              </Typography>
              <Typography color="textPrimary" variant="h5">
                Outras informações
              </Typography>
              {groupById !== '' ? (
                <Typography color="textSecondary" variant="body2">
                  Grupo - Classe: {groupById} - {classById}
                </Typography>
              ) : null}
              {pdmById ? (
                <Typography color="textSecondary" variant="body2">
                  PDM: {pdmById}
                </Typography>
              ) : null}
              {materialById ? (
                <Typography color="textSecondary" variant="body2">
                  Material: {materialById}
                </Typography>
              ) : null}
              {sessionById !== '' ? (
                <Typography color="textSecondary" variant="body2">
                  Sessão - Divisão: {sessionById} - {divisionById}
                </Typography>
              ) : null}
              {serviceGroupById ? (
                <Typography color="textSecondary" variant="body2">
                  Grupo: {serviceGroupById}
                </Typography>
              ) : null}
              {serviceClassById ? (
                <Typography color="textSecondary" variant="body2">
                  Classe: {serviceClassById}
                </Typography>
              ) : null}
              {serviceSubclassById ? (
                <Typography color="textSecondary" variant="body2">
                  Subclasse: {serviceSubclassById}
                </Typography>
              ) : null}
              {serviceById ? (
                <Typography color="textSecondary" variant="body2">
                  Serviço: {serviceById}
                </Typography>
              ) : null}
              <Typography color="textSecondary" variant="body2">
                Linha de fornecimento: {product.supply_line}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Natureza da despesa: {product.expense_nature}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Elemento/Item de despesa: {product.expense_product}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                ---
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Usuário responsável: {product.user_name} - {product.type === 3 ? 'Administrador' : product.type === 1 ? 'Órgão' : 'Servidor'}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                E-mail: {product.email}
              </Typography>

              {documents.length > 0 ? (
                <div>
                  <Typography color="textPrimary" variant="h5">
                    Anexos
                  </Typography>
                  <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                    {documents.map((item) =>
                      <div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <a target="_blank" href={`${apiStringServer}/docs/${item.document_url}`} rel="noreferrer">
                            <Typography variant="subtitle1" color="textSecondary" align="left">
                              {item.document_url}
                            </Typography>
                          </a>

                          <Button
                            startIcon={<Close style={{ marginTop: -5, marginRight: -5 }} />}
                            onClick={async () => {
                              deleteDocument(item.id, item.document_url);
                            }}
                            style={{ color: '#001D5F' }}
                          >

                          </Button>
                        </div>
                        <Divider />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              {links.length > 0 ? (
                <div>
                  <Typography color="textPrimary" variant="h5">
                    Processos
                  </Typography>
                  <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                    {links.map((item) =>
                      <div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <a target="_blank" href={`${item.process_url}`} rel="noreferrer">
                            <Typography variant="subtitle1" color="textSecondary" align="left">
                              {item.process_url}
                            </Typography>
                          </a>

                          <Button
                            startIcon={<Close style={{ marginTop: -5, marginRight: -5 }} />}
                            onClick={async () => {
                              deleteLink(item.id);
                            }}
                            style={{ color: '#001D5F' }}
                          >

                          </Button>
                        </div>
                        <Divider />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              <div>

                <Card sx={{ backgroundColor: '#FFF', height: 500, width: 500 }}>
                  <Typography variant="h6" color="textPrimary" style={{ margin: 10 }}>Progresso das etapas</Typography>

                  <ResponsiveContainer width="125%" height="100%" style={{
                    marginTop: -50,
                    marginLeft: 20,
                  }}>
                    <PieChart style={{ width: '75%' }}>
                      <Pie
                        data={stepsChart}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label={({ name, percent }) => `${name} `} //(${(percent * 100).toFixed(0)}%)
                        outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {stepsChart.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend verticalAlign="bottom" height={50} width={'100%'} />
                    </PieChart>
                  </ResponsiveContainer>
                </Card>

              </div>
            </Box>
          )}

        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => setOpenStepsDialog(true)}
            color="primary"
            variant="contained"
            startIcon={<FormatListNumbered />}
            sx={styles.button}
          >
            Verificar etapas
          </Button>
          <Button onClick={() => setOpenDetailsDialog(false)}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog das etapas */}
      <Dialog open={openStepsDialog} onClose={() => setOpenStepsDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Conclusão de etapas</DialogTitle>
        <DialogContent>
          <DialogContentText color="#fff">
            Certifique-se de já ter concluído todas as etapas necessárias para a homologação do status do produto/serviço.
          </DialogContentText>

          <Box sx={{ maxWidth: 400 }}>
            <Stepper nonLinear orientation="vertical" activeStep={activeStep}>
              {steps.map((step, index) =>
                <Step key={step.label} completed={completed[index]}>
                  <StepButton onClick={() => handleStep(index)}>
                    {step.label}
                  </StepButton>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                    {index === 1 ? (
                      <div>
                        <input
                          accept="file_extension|audio/*|video/*|image/*|media_type"
                          style={{ display: 'none' }}
                          id="button-attach"
                          multiple
                          type="file"
                          onChange={(e) => handleNewFile(e)}
                        />
                        <label htmlFor="button-attach">
                          <Button
                            component="span"
                            variant="contained"
                            size="small"
                            color="primary"
                            style={styles.button}
                            startIcon={<AttachFile />}
                          >
                            Anexar documento
                          </Button>
                        </label>

                        {documents.length > 0 ? (
                          <div style={{ display: 'flex', flexDirection: 'column', margin: 10, marginTop: 20 }}>
                            {documents.map((item) =>
                              <div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <a target="_blank" href={`${apiStringServer}/docs/${item.document_url}`} rel="noreferrer">
                                    <Typography variant="subtitle1" color="textSecondary" align="left">
                                      {item.document_url}
                                    </Typography>
                                  </a>

                                  <Button
                                    startIcon={<Close style={{ marginTop: -5, marginRight: -5 }} />}
                                    onClick={async () => {
                                      deleteDocument(item.id, item.document_url);
                                    }}
                                    style={{ color: '#001D5F' }}
                                  >

                                  </Button>
                                </div>
                                <Divider />
                              </div>
                            )}
                          </div>
                        ) : null}

                        {open ? (
                          <Alert
                            style={{ marginBottom: 10 }}
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                <Close fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {msg}
                          </Alert>
                        ) : null}

                        {openError ? (
                          <Alert
                            style={{ marginBottom: 10 }}
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpenError(false);
                                }}
                              >
                                <Close fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {msg}
                          </Alert>
                        ) : null}
                      </div>
                    ) : index === 2 ? (
                      <div>
                        <div style={styles.directionRow}>
                          <TextField
                            margin="dense"
                            id="link"
                            label="Link"
                            type="text"
                            fullWidth
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                          />
                          <Button
                            onClick={() => handleLink()}
                            color="primary"
                            variant="contained"
                            startIcon={<AddLink />}
                            sx={styles.button2}
                          >
                            Anexar link
                          </Button>
                        </div>

                        {links.length > 0 ? (
                          <div style={{ display: 'flex', flexDirection: 'column', margin: 10, marginTop: 20 }}>
                            {links.map((item) =>
                              <div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <a target="_blank" href={`${item.process_url}`} rel="noreferrer">
                                    <Typography variant="subtitle1" color="textSecondary" align="left">
                                      {item.process_url}
                                    </Typography>
                                  </a>

                                  <Button
                                    startIcon={<Close style={{ marginTop: -5, marginRight: -5 }} />}
                                    onClick={async () => {
                                      deleteLink(item.id);
                                    }}
                                    style={{ color: '#001D5F' }}
                                  >

                                  </Button>
                                </div>
                                <Divider />
                              </div>
                            )}
                          </div>
                        ) : null}

                        {open2 ? (
                          <Alert
                            style={{ marginBottom: 10 }}
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpen2(false);
                                }}
                              >
                                <Close fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {msg}
                          </Alert>
                        ) : null}

                        {openError2 ? (
                          <Alert
                            style={{ marginBottom: 10 }}
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpenError2(false);
                                }}
                              >
                                <Close fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {msg}
                          </Alert>
                        ) : null}
                      </div>
                    ) : index === 3 ? (
                      <div>

                        <Card sx={{ backgroundColor: '#FFF', height: 500, width: 500 }}>
                          <Typography variant="h6" color="textPrimary" style={{ margin: 10 }}>Progresso das etapas</Typography>

                          <ResponsiveContainer width="125%" height="100%" style={{
                            marginTop: -50,
                            marginLeft: 20,
                          }}>
                            <PieChart style={{ width: '75%' }}>
                              <Pie
                                data={stepsChart}
                                cx="50%"
                                cy="50%"
                                labelLine={true}
                                label={({ name, percent }) => `${name} `} //(${(percent * 100).toFixed(0)}%)
                                outerRadius={150}
                                fill="#8884d8"
                                dataKey="value"
                              >
                                {stepsChart.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}
                              </Pie>
                              <Tooltip />
                              <Legend verticalAlign="bottom" height={50} width={'100%'} />
                            </PieChart>
                          </ResponsiveContainer>
                        </Card>

                      </div>
                    ) : null}

                  </StepContent>
                </Step>
              )}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>Todas as etapas foram concluídas.</Typography>
              </Paper>
            )}
          </Box>

        </DialogContent>
        <DialogActions>
          <Box>
            <Button
              onClick={() => setOpenStepsDialog(false)}
              style={{ color: '#001D5F' }}
              startIcon={<Close style={{ marginTop: -4, marginRight: -5 }} />}
            >
              Cancelar
            </Button>
            <Button
              disabled={disabled}
              startIcon={<Description style={{ marginTop: -5, marginRight: -5 }} />}
              onClick={async () => {
                //await generateDocument();
                setOpenStepsDialog(false);
              }}
              style={{ color: disabled === false ? '#001D5F' : null }}
            >
              Homologar
            </Button>
            {/*<a id="pdfdoc" href="" target="_blank" rel="noreferrer" />*/}
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cadastro de {type}</DialogTitle>
        <DialogContent>
          <DialogContentText color="#000">
            Preencha com o nome do item que deseja cadastrar.
          </DialogContentText>

          {type !== 'Estado' ? (
            <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
              <InputLabel htmlFor="outlined-secretary-native-simple">
                Item pai *
              </InputLabel>
              <Select
                native
                required
                value={parent_id}
                onChange={handleChangeParent}
                inputProps={{
                  name: 'secretary',
                  id: 'outlined-secretary-native-simple',
                }}
              >
                <option value="select">Selecione um item</option>
                {tree.map((item) =>
                  <option value={`${item.id}`} id={`${item.id}`} label={item.parent_name ? item.parent_name + ' / ' + item.name : '' + item.name} />
                )}
              </Select>
            </FormControl>
          ) : null}

          {type === 'Estado' ? (
            <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
              <InputLabel htmlFor="outlined-secretary-native-simple">
                Sigla do estado *
              </InputLabel>
              <Select
                native
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Sigla do estado"
                inputProps={{
                  name: 'secretary',
                  id: 'outlined-secretary-native-simple',
                }}
              >
                <option value="select">Selecione uma sigla</option>
                {statesArray.map((initials) =>
                  <option value={`${initials.name}`}>{initials.name}</option>
                )}
              </Select>
            </FormControl>
          ) : (
            <Box>
              {type === 'Município' ? (
                <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                  <InputLabel htmlFor="outlined-secretary-native-simple">
                    Município *
                  </InputLabel>
                  <Select
                    native
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label="Município"
                    inputProps={{
                      name: 'secretary',
                      id: 'outlined-secretary-native-simple',
                    }}
                  >
                    <option value="select">Selecione um município</option>
                    {cidades.map((item) =>
                      <option value={item.name}>{item.name}</option>
                    )}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Nome"
                  type="text"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              )}
            </Box>
          )}

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>
            Cancelar
          </Button>
          <Button onClick={async () => {
            setOpenDialog(false);
            handleSubmitTreeItem(name, type, parent_id);
          }}>
            Cadastrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openProductDialog} onClose={() => setOpenProductDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cadastro de Produto/Serviço</DialogTitle>
        <DialogContent>
          <DialogContentText color="#000">
            Preencha com os dados do produto/serviço.
          </DialogContentText>

          <Grid item xs={12}>
            <input
              accept="file_extension|image"
              style={{ display: 'none' }}
              id="button-attach"
              multiple
              type="file"
              onChange={handleNewImage}
            />
            <label htmlFor="button-attach">
              <Avatar alt="" src={avatar} sx={{ width: 132, height: 106, margin: 5, borderRadius: 0 }} />
            </label>
          </Grid>

          {type !== 'Estado' ? (
            <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
              <InputLabel htmlFor="outlined-secretary-native-simple">
                Item pai *
              </InputLabel>
              <Select
                native
                required
                value={parent_id}
                onChange={handleChangeParent}
                inputProps={{
                  name: 'secretary',
                  id: 'outlined-secretary-native-simple',
                }}
              >
                <option value="select">Selecione um item</option>
                {tree.map((item) =>
                  <option value={`${item.id}`} label={item.parent_name ? item.parent_name + ' / ' + item.name : '' + item.name}></option>
                )}
              </Select>
            </FormControl>
          ) : null}

          <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
            <InputLabel htmlFor="outlined-item_type-native-simple">
              Tipo do item *
            </InputLabel>
            <Select
              native
              required
              value={itemType}
              onChange={(e) => setItemType(e.target.value)}
              label="Tipo do item"
              inputProps={{
                name: 'item_type',
                id: 'outlined-item_type-native-simple',
              }}
            >
              <option value="select">Selecione um tipo de item</option>
              <option value="material">Material</option>
              <option value="service">Serviço</option>
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
            <InputLabel htmlFor="outlined-secretary-native-simple">
              Selecione uma situação *
            </InputLabel>
            <Select
              native
              required
              value={situation}
              onChange={(e) => setSituation(e.target.value)}
              label="Situação"
              inputProps={{
                name: 'secretary',
                id: 'outlined-secretary-native-simple',
              }}
            >
              <option value="select">Selecione uma situação</option>
              <option value="ativo">Ativo</option>
              <option value="suspenso">Suspenso para compra</option>
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
            <InputLabel htmlFor="outlined-secretary-native-simple">
              Natureza da despesa *
            </InputLabel>
            <Select
              native
              required
              value={expenseNature}
              onChange={(e) => setExpenseNature(e.target.value)}
              label="Natureza da despesa"
              inputProps={{
                name: 'secretary',
                id: 'outlined-secretary-native-simple',
              }}
            >
              <option value="select">Selecione a natureza da despesa</option>
              <option value="consumo">Produto de consumo</option>
              <option value="permanete">Produto permanente</option>
              <option value="obras">Obras</option>
              <option value="consumopermanente">Consumo/Permanente</option>
            </Select>
          </FormControl>

          {itemType === 'material' ? (
            <Box>
              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-group-native-simple">
                  Grupo *
                </InputLabel>
                <Select
                  native
                  required
                  value={group}
                  onChange={(e) => { setGroup(e.target.value); getClassList(e.target.value); }}
                  label="Grupo"
                  inputProps={{
                    name: 'group',
                    id: 'outlined-group-native-simple',
                  }}
                >
                  <option value="select">Selecione o grupo do item</option>
                  {groupList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-class-native-simple">
                  Classe *
                </InputLabel>
                <Select
                  native
                  required
                  value={classification}
                  onChange={(e) => { setClassification(e.target.value); getPdmList(e.target.value); }}
                  label="Classe"
                  inputProps={{
                    name: 'class',
                    id: 'outlined-class-native-simple',
                  }}
                >
                  <option value="select">Selecione o classe do item</option>
                  {classList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-pdm-native-simple">
                  Padrão descritivo do material (PDM) *
                </InputLabel>
                <Select
                  native
                  required
                  value={pdm}
                  onChange={(e) => { setPdm(e.target.value); getMaterialList(e.target.value); }}
                  label="Padrão descritivo do material (PDM)"
                  inputProps={{
                    name: 'pdm',
                    id: 'outlined-pdm-native-simple',
                  }}
                >
                  <option value="select">Selecione o PDM do item</option>
                  {pdmList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-material-native-simple">
                  Material *
                </InputLabel>
                <Select
                  native
                  required
                  value={material}
                  onChange={(e) => { setMaterial(e.target.value); }}
                  label="Material"
                  inputProps={{
                    name: 'material',
                    id: 'outlined-material-native-simple',
                  }}
                >
                  <option value="select">Selecione o material do item</option>
                  {materialList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <Box>
              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-session-native-simple">
                  Sessão *
                </InputLabel>
                <Select
                  native
                  required
                  value={session}
                  onChange={(e) => { setSession(e.target.value); getDivisionList(e.target.value); }}
                  label="Sessão"
                  inputProps={{
                    name: 'session',
                    id: 'outlined-session-native-simple',
                  }}
                >
                  <option value="select">Selecione a sessão do item</option>
                  {sessionList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-division-native-simple">
                  Divisão *
                </InputLabel>
                <Select
                  native
                  required
                  value={division}
                  onChange={(e) => { setDivision(e.target.value); getServiceGroupList(e.target.value); }}
                  label="Divisão"
                  inputProps={{
                    name: 'division',
                    id: 'outlined-division-native-simple',
                  }}
                >
                  <option value="select">Selecione a divisão do item</option>
                  {divisionList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-serviceGroup-native-simple">
                  Grupo *
                </InputLabel>
                <Select
                  native
                  required
                  value={serviceGroup}
                  onChange={(e) => { setServiceGroup(e.target.value); getServiceClassList(e.target.value); }}
                  label="Grupo"
                  inputProps={{
                    name: 'serviceGroup',
                    id: 'outlined-serviceGroup-native-simple',
                  }}
                >
                  <option value="select">Selecione a grupo do item</option>
                  {serviceGroupList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-serviceClass-native-simple">
                  Classe *
                </InputLabel>
                <Select
                  native
                  required
                  value={serviceClass}
                  onChange={(e) => { setServiceClass(e.target.value); getServiceSubclassList(e.target.value); getServiceList(null); }}
                  label="Classe"
                  inputProps={{
                    name: 'serviceClass',
                    id: 'outlined-serviceClass-native-simple',
                  }}
                >
                  <option value="select">Selecione a classe do item</option>
                  {serviceClassList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-serviceSubclass-native-simple">
                  Subclasse *
                </InputLabel>
                <Select
                  native
                  required
                  value={serviceSubclass}
                  onChange={(e) => { setServiceSubclass(e.target.value); getServiceList(e.target.value); }}
                  label="Subclasse"
                  inputProps={{
                    name: 'serviceSubclass',
                    id: 'outlined-serviceSubclass-native-simple',
                  }}
                >
                  <option value="select">Selecione a subclasse do item</option>
                  {serviceSubclassList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-service-native-simple">
                  Serviço *
                </InputLabel>
                <Select
                  native
                  required
                  value={service}
                  onChange={(e) => { setService(e.target.value); }}
                  label="Serviço"
                  inputProps={{
                    name: 'service',
                    id: 'outlined-service-native-simple',
                  }}
                >
                  <option value="select">Selecione o serviço do item</option>
                  {serviceList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>
            </Box>
          )}

          <TextField
            margin="dense"
            id="name"
            label="Nome"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            margin="dense"
            id="code"
            label="Código do produto (NCM)"
            type="text"
            fullWidth
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />

          <TextField
            margin="dense"
            id="supplyLine"
            label="Linha de forneceimento"
            type="text"
            fullWidth
            value={supplyLine}
            onChange={(e) => setSupplyLine(e.target.value)}
          />

          <TextField
            margin="dense"
            id="expenseProduct"
            label="Produto de despesa"
            type="text"
            fullWidth
            value={expenseProduct}
            onChange={(e) => setExpenseProduct(e.target.value)}
          />

          <TextField
            margin="dense"
            type="number"
            id="productValue"
            label="Valor do produto"
            fullWidth
            value={productValue}
            onChange={(e) => setProductValue(e.target.value)}
          />

          <TextField
            margin="dense"
            id="productDescription"
            label="Descrição do produto"
            type="text"
            multiline
            fullWidth
            value={productDescription}
            onChange={(e) => setProductDescription(e.target.value)}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenProductDialog(false)}>
            Cancelar
          </Button>
          <Button onClick={async () => {
            setOpenProductDialog(false);
            handleSubmitProduct(photo_url, parent_id, itemType, situation, code, name, expenseNature, group, classification, pdm,
              material, session, division, serviceGroup, serviceClass, serviceSubclass, service, supplyLine, expenseProduct, productValue, productDescription);
          }}>
            Cadastrar
          </Button>
        </DialogActions>
      </Dialog>

      {/** Dialog de edição */}

      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Alteração de Produto/Serviço</DialogTitle>
        <DialogContent>
          <DialogContentText color="#000">
            Altere os dados do produto/serviço.
          </DialogContentText>

          <Grid item xs={12}>
            <input
              accept="file_extension|image"
              style={{ display: 'none' }}
              id="button-attach"
              multiple
              type="file"
              onChange={handleNewImage}
            />
            <label htmlFor="button-attach">
              <Avatar alt="" src={`${apiStringServer}/products_img/${avatarEdit}`} sx={{ width: 132, height: 106, margin: 5, borderRadius: 0 }} />
            </label>
          </Grid>

          <FormControl variant="outlined" fullWidth style={{ marginBottom: 15 }}>
            <InputLabel htmlFor="outlined-item_type-native-simple">
              Tipo do item *
            </InputLabel>
            <Select
              native
              required
              value={itemType}
              disabled
              onChange={(e) => setItemType(e.target.value)}
              label="Tipo do item"
              inputProps={{
                name: 'item_type',
                id: 'outlined-item_type-native-simple',
              }}
            >
              <option value="select">Selecione um tipo de item</option>
              <option value="material">Material</option>
              <option value="service">Serviço</option>
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
            <InputLabel htmlFor="outlined-secretary-native-simple">
              Natureza da despesa *
            </InputLabel>
            <Select
              native
              required
              value={expenseNature}
              onChange={(e) => setExpenseNature(e.target.value)}
              label="Natureza da despesa"
              inputProps={{
                name: 'secretary',
                id: 'outlined-secretary-native-simple',
              }}
            >
              <option value="select">Selecione a natureza da despesa</option>
              <option value="consumo">Produto de consumo</option>
              <option value="permanete">Produto permanente</option>
              <option value="obras">Obras</option>
              <option value="consumopermanente">Consumo/Permanente</option>
            </Select>
          </FormControl>

          {itemType === 'material' ? (
            <Box>
              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-group-native-simple">
                  Grupo *
                </InputLabel>
                <Select
                  native
                  required
                  value={group}
                  onChange={(e) => { setGroup(e.target.value); getClassList(e.target.value); }}
                  label="Grupo"
                  inputProps={{
                    name: 'group',
                    id: 'outlined-group-native-simple',
                  }}
                >
                  <option value="select">Selecione o grupo do item</option>
                  {groupList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-class-native-simple">
                  Classe *
                </InputLabel>
                <Select
                  native
                  required
                  value={classification}
                  onChange={(e) => { setClassification(e.target.value); getPdmList(e.target.value); }}
                  label="Classe"
                  inputProps={{
                    name: 'class',
                    id: 'outlined-class-native-simple',
                  }}
                >
                  <option value="select">Selecione o classe do item</option>
                  {classList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-pdm-native-simple">
                  Padrão descritivo do material (PDM) *
                </InputLabel>
                <Select
                  native
                  required
                  value={pdm}
                  onChange={(e) => { setPdm('0' + e.target.value); getMaterialList(e.target.value); }}
                  label="Padrão descritivo do material (PDM)"
                  inputProps={{
                    name: 'pdm',
                    id: 'outlined-pdm-native-simple',
                  }}
                >
                  <option value="select">Selecione o PDM do item</option>
                  {pdmList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-material-native-simple">
                  Material *
                </InputLabel>
                <Select
                  native
                  required
                  value={material}
                  onChange={(e) => { setMaterial(e.target.value); }}
                  label="Material"
                  inputProps={{
                    name: 'material',
                    id: 'outlined-material-native-simple',
                  }}
                >
                  <option value="select">Selecione o material do item</option>
                  {materialList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <Box>
              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-session-native-simple">
                  Sessão *
                </InputLabel>
                <Select
                  native
                  required
                  value={session}
                  onChange={(e) => { setSession(e.target.value); getDivisionList(e.target.value); }}
                  label="Sessão"
                  inputProps={{
                    name: 'session',
                    id: 'outlined-session-native-simple',
                  }}
                >
                  <option value="select">Selecione a sessão do item</option>
                  {sessionList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-division-native-simple">
                  Divisão *
                </InputLabel>
                <Select
                  native
                  required
                  value={division}
                  onChange={(e) => { setDivision(e.target.value); getServiceGroupList(e.target.value); }}
                  label="Divisão"
                  inputProps={{
                    name: 'division',
                    id: 'outlined-division-native-simple',
                  }}
                >
                  <option value="select">Selecione a divisão do item</option>
                  {divisionList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-serviceGroup-native-simple">
                  Grupo *
                </InputLabel>
                <Select
                  native
                  required
                  value={serviceGroup}
                  onChange={(e) => { setServiceGroup(e.target.value); getServiceClassList(e.target.value); }}
                  label="Grupo"
                  inputProps={{
                    name: 'serviceGroup',
                    id: 'outlined-serviceGroup-native-simple',
                  }}
                >
                  <option value="select">Selecione a grupo do item</option>
                  {serviceGroupList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-serviceClass-native-simple">
                  Classe *
                </InputLabel>
                <Select
                  native
                  required
                  value={serviceClass}
                  onChange={(e) => { setServiceClass(e.target.value); getServiceSubclassList(e.target.value); getServiceList(null); }}
                  label="Classe"
                  inputProps={{
                    name: 'serviceClass',
                    id: 'outlined-serviceClass-native-simple',
                  }}
                >
                  <option value="select">Selecione a classe do item</option>
                  {serviceClassList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-serviceSubclass-native-simple">
                  Subclasse *
                </InputLabel>
                <Select
                  native
                  required
                  value={serviceSubclass}
                  onChange={(e) => { setServiceSubclass(e.target.value); getServiceList(e.target.value); }}
                  label="Subclasse"
                  inputProps={{
                    name: 'serviceSubclass',
                    id: 'outlined-serviceSubclass-native-simple',
                  }}
                >
                  <option value="select">Selecione a subclasse do item</option>
                  {serviceSubclassList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth style={{ marginBottom: 15, marginTop: 10 }}>
                <InputLabel htmlFor="outlined-service-native-simple">
                  Serviço *
                </InputLabel>
                <Select
                  native
                  required
                  value={service}
                  onChange={(e) => { setService(e.target.value); }}
                  label="Serviço"
                  inputProps={{
                    name: 'service',
                    id: 'outlined-service-native-simple',
                  }}
                >
                  <option value="select">Selecione o serviço do item</option>
                  {serviceList.map((item) =>
                    <option value={`${item.codigo}`}>{item.descricao}</option>
                  )}
                </Select>
              </FormControl>
            </Box>
          )}

          <TextField
            margin="dense"
            id="name"
            label="Nome"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            margin="dense"
            id="code"
            label="Código do produto (NCM)"
            type="text"
            fullWidth
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />

          <TextField
            margin="dense"
            id="supplyLine"
            label="Linha de forneceimento"
            type="text"
            fullWidth
            value={supplyLine}
            onChange={(e) => setSupplyLine(e.target.value)}
          />

          <TextField
            margin="dense"
            id="expenseProduct"
            label="Produto de despesa"
            type="text"
            fullWidth
            value={expenseProduct}
            onChange={(e) => setExpenseProduct(e.target.value)}
          />

          <TextField
            margin="dense"
            type="number"
            id="productValue"
            label="Valor do produto"
            fullWidth
            value={productValue}
            onChange={(e) => setProductValue(e.target.value)}
          />

          <TextField
            margin="dense"
            id="productDescription"
            label="Descrição do produto"
            type="text"
            multiline
            fullWidth
            value={productDescription}
            onChange={(e) => setProductDescription(e.target.value)}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>
            Cancelar
          </Button>
          <Button onClick={async () => {
            setOpenEditDialog(false);
            handleEditProduct();
          }}>
            Alterar
          </Button>
        </DialogActions>
      </Dialog>

    </Card>
  );
}
